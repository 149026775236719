<template>
  <div class="buyRegister bg-gray">
    <div class="headerTitle">
      <img class="img" :src="`${publickPath}static/img/photo/buy.png`" />
    </div>
    <div class="box" style="">
      <div class="field-box">
        <label>商家名称</label>
        <div class="content">{{ formData.Name }}</div>
      </div>

      <div class="field-box">
        <label>省市区</label>
        <div class="content">{{ formData.region }}</div>
      </div>

      <div class="field-box">
        <label>详细地址</label>
        <div class="content">{{ formData.address }}</div>
      </div>
    </div>
    <div class="box" style="margin-top: 6px; height: auto">
      <div class="field-box" style="height: auto; align-items: flex-start">
        <label style="padding-top: 1rem">商品</label>
        <div
          class="content"
          style="height: auto; padding: 1rem 0; min-height: 3rem"
        >
          <div class="thisBox" v-if="columns">
            <div
              v-for="(item, index) in columns"
              :key="index"
              :class="{ active: item.isActive ? true : false, boxDiv: true }"
              @click="selectGoods(item.isActive, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

        <div style="width: 100%; height: 8rem; position: relative">
      <u-button
        @click="submitBtn"
        size="large"
        style="
          position: absolute;

          left: 50%;
          transform: translateX(-50%);
          bottom: 2rem;
        "
        type="primary"
        >提交</u-button
      >
    </div>

    <!-- <u-button
      @click="submitBtn"
      size="large"
      style="
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
      "
      type="primary"
      >提交</u-button
    > -->

    <!-- <van-form ref="formData" class="u-form">
      <van-field
        clearable
        disabled
        v-model="formData.Name"
        label="商家名称"
        placeholder="请输入商家名称"
        :rules="[{ required: true, message: '请输入商家名称' }]"
      />
      <van-field
        readonly
        clickable
        disabled
        label="省市区"
        v-model="formData.region"
        :rules="[{ required: true, message: '请选择省市区' }]"
        placeholder="点击选择省市区"
        @click="showAreaPicker = true"
      />

      <van-field
        clearable
        disabled
        v-model="formData.address"
        label="详细地址"
        placeholder="请输入详细地址"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />
      <van-field
        style="margin-top: 6px"
        v-model="formData.goods"
        required
        readonly
        clickable
        label="商品"
        placeholder="请你选择商品"
        right-icon="arrow"
        :rules="[{ required: true, message: '请你选择商品' }]"
        @click="
          showPicker = true;
          pickType = 'spzt';
        "
      ></van-field>

      <u-button
        @click="submitBtn"
        size="large"
        style="
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          bottom: 2rem;
        "
        type="primary"
        >提交</u-button
      >
    </van-form>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        ref="picker"
        title="商品"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <van-popup v-model="showAreaPicker" round position="bottom">
      <van-area
        title="省市区"
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="showAreaPicker = false"
      />
    </van-popup> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import {
  businessGoodsType,
  businessMyEdit,
  businessSaveRecords,
} from "@utils/aRequest";
import UButton from "@/components/u-button";
import api from "@api";
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      businessId: this.$route.query.id,
      formData: {
        Name: "",
        region: "",
        address: "",
        goods: "",
        goodsId: "",
      },
      showAreaPicker: false,
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {},
      },

      showPicker: false,
      columns: [],
    };
  },
  components: { UButton },
  watch: {},
  created() {
    this.getInitialization();
    // alert(this.$route.query.id)
    // this.getAreaList();
  },
  methods: {
    selectGoods(isActive, index) {
      this.columns[index].isActive = !isActive;
    },
    getInitialization() {
      var self = this;
      // let id = "b082c24dc0cc4072a419754e471e6b87";

      businessGoodsType({ businessId: self.businessId }).then((data) => {
        if (data.code == "SUCCESS") {
          console.log(data);
          let da = data.data;
          da.forEach((item) => {
            item.isActive = false;
          });
          self.columns = da;
        }
      });
      businessMyEdit({ id: self.businessId }).then((data) => {
        if (data.code == "SUCCESS") {
          console.log(data);
          self.formData.Name = data.data.spmc;
          self.formData.region = data.data.region;
          self.formData.address = data.data.address;
        }
      });
    },
    // 提交按钮
    submitBtn() {
      var self = this;

      var selectColumns = [];
      let columns = self.columns;
      columns.forEach((item) => {
        if (item.isActive) {
          selectColumns.push(item.code);
        }
      });

      if (selectColumns && selectColumns.length > 0) {
        businessSaveRecords({
          businessId: self.businessId,
          goodsTypeIds: selectColumns,
        }).then((data) => {
          if (data.code == "SUCCESS") {
            console.log(data);
            this.$router.push({
              path: "/goods/BuyRegisterOver",
              query: { id: data.data },
            });
          }
        });
      } else {
        Toast.fail("请选择商品");
      }
    },

    // 弹窗选择
    onConfirm(value) {
      console.log("value: ", value);
      if (value) {
        this.formData.goods = value.text;
        this.formData.goodsId = value.value;
      }
      this.showPicker = false;
    },
  },
  mounted() {
    let roleData = JSON.parse(localStorage.getItem("roleData"));
    // console.log(roleData);
    // return false
    this.$globalFun.checkUserState()
    // if (!this.$globalFun.checkUserState()) {}
    // if (roleData.userPO.rlsbStatus != 1) {
    //   this.$toastModal()
    //   // Dialog.alert({
    //   //   title: "欢迎你加入在线社区",
    //   //   message: "为了确保所有功能正常使用,请完成实名认证流程，谢谢！",
    //   // }).then(() => {
    //   //   this.$router.push({
    //   //     path: "/realNameAuthentication",
    //   //     query: {
    //   //       id: this.$route.query.id,
    //   //       type: "buy",
    //   //     },
    //   //   }); //去实名认证

    //   // });
    // }
  },
};
</script>

<style lang="less" scoped>
.buyRegister {
  height: 100%;
  overflow-y: auto;
  .headerTitle {
    .img {
      width: 100%;
      height: 5.2rem;
    }
  }

  .box {
    padding: 0 1.066667rem /* 20/18.75 */;
    background-color: #fff;
  }
  .field-box {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 0.8rem /* 15/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: @fcb;
    border-bottom: 1px solid #ebedf0;
    &.nobb {
      border-bottom: none;
    }
    label {
      width: 5.066667rem /* 95/18.75 */;
    }
    .content {
      flex: 1;
    }
  }

  .thisBox {
    width: 100%;
    .boxDiv {
      float: left;
      width: 30%;
      border: 1px solid #888888;
      color: #969696;
      font-size: 0.6rem;
      padding: 0.2rem 0;
      border-radius: 10rem;
      margin-bottom: 0.8rem;
      text-align: center;
    }
    .boxDiv:nth-child(3n-1) {
      margin: 0 5%;
    }
  }

  .active {
    color: #2b71fd !important;
    border: 1px solid #2b71fd !important;
  }
}
</style>
